import React from 'react';
import { Link } from 'react-router-dom';
import { TranslatableText } from '../../../providers/LanguageProvider';

const DesktopLayout = (props) => (

    <React.Fragment>
        <ul className="links">
            <li>
                <Link to={`/${props.lang}/our-menu`}>
                <TranslatableText dictionary={{
                    en: "Our Menu",
                    fr: "Notre menu" }}>
                </TranslatableText> 
                </Link>&nbsp;|&nbsp;
            </li>
            <li>
                <Link to={`/${props.lang}/our-values`}>
                <TranslatableText dictionary={{
                    en: "Our Values",
                    fr: "Nos valeurs" }}>
                </TranslatableText> 
                </Link>&nbsp;|&nbsp;
            </li>
            <li>
                <Link to={`/${props.lang}/order`}>
                <TranslatableText dictionary={{
                    en: "Our App",
                    fr: "Notre appli" }}>
                </TranslatableText> 
                </Link>&nbsp;|&nbsp;
            </li>
            <li>
                <Link to={`/${props.lang}/locations`}>
                <TranslatableText dictionary={{
                    en: "Locations",
                    fr: "Restaurants" }}>
                </TranslatableText> 
                </Link>&nbsp;|&nbsp;
            </li>
            <li>
                <Link to={`/${props.lang}/faq`}>
                <TranslatableText dictionary={{
                    en: "FAQ",
                    fr: "Foire aux questions" }}>
                </TranslatableText> 
                </Link>&nbsp;|&nbsp;
            </li>
            <li>
                <Link to={`/${props.lang}/gift-cards`}>
                <TranslatableText dictionary={{
                    en: "Gift Cards",
                    fr: "Cartes-cadeaux" }}>
                </TranslatableText> 
                </Link>&nbsp;|&nbsp;
            </li>
            <li>
                <a href={props.lang === 'fr' ? 'https://workwithus.aw.ca/jobs-fr' : 'https://workwithus.aw.ca/'}>
                <TranslatableText dictionary={{
                    en: "Work with Us",
                    fr: "Travailler avec nous" }}>
                </TranslatableText> 
                </a>
            </li>
        </ul>

        <ul className="links">
            <li>
                <a href={props.lang === 'fr' ? 'https://awfranchise.ca/fr' : 'https://awfranchise.ca' }>
                <TranslatableText dictionary={{
                    en: "Franchise Info",
                    fr: "Information sur les franchises" }}>
                </TranslatableText> 
                </a>&nbsp;|&nbsp;
            </li>
            <li>
                <Link to={`/${props.lang}/company`}>
                <TranslatableText dictionary={{
                    en: "Our Company",
                    fr: "Notre entreprise" }}>
                </TranslatableText> 
                </Link>&nbsp;|&nbsp;
            </li>
            <li>
                <a href="https://www.awcda.ca/">
                <TranslatableText dictionary={{
                    en: "Operator Login",
                    fr: "Entrée des exploitants" }}>
                </TranslatableText> 
                </a>&nbsp;|&nbsp;
            </li>
            <li>
                <a href="https://awinvestors.ca">
                <TranslatableText dictionary={{
                    en: "Investor Relations",
                    fr: "Relations investisseurs" }}>
                </TranslatableText> 
                </a>&nbsp;|&nbsp;
            </li>
            <li>
                <Link to={`/${props.lang}/contact-us`}>
                <TranslatableText dictionary={{
                    en: "Contact Us",
                    fr: "Contactez-nous" }}>
                </TranslatableText> 
                </Link>&nbsp;|&nbsp;
            </li>
            <li>
                <Link to={`/${props.lang}/terms`}>
                <TranslatableText dictionary={{
                    en: "Terms",
                    fr: "Conditions" }}>
                </TranslatableText> 
                </Link>&nbsp;|&nbsp;
            </li>
            <li>
                <Link to={`/${props.lang}/privacy`}>
                <TranslatableText dictionary={{
                    en: "Privacy Policy",
                    fr: "Politique de confidentialité" }}>
                </TranslatableText> 
                </Link>
            </li>
        </ul>
    </React.Fragment>

)
export default DesktopLayout;